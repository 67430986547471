import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Button from './Button';
import SimpleSelect, { SelectItem } from './SimpleSelect';

export interface SelectInputProps {
  items: SelectItem<string | any>[];
  placeholder?: string;
  renderPlaceholder?: (value: string, items?: SelectItem<string>[]) => React.ReactNode;
  control: Control<any, any>;
  name: string;
  rules?: any;
  className?: string;
  disabled?: boolean;
}

function SelectInput(props: SelectInputProps) {
  const { items, placeholder, control, name, rules, renderPlaceholder, className, disabled } = props;
  return (
    <Controller
      render={({ field }) => (
        <div className="flex gap-2 w-full">
          <div className="w-full flex-1">
            <SimpleSelect
              disabled={disabled}
              className={className}
              placeholder={(renderPlaceholder?.(field.value) as string) ?? placeholder}
              items={items}
              value={typeof field.value === 'object' ? field.value?.id : field.value}
              onChange={field.onChange}
            />
          </div>
          {field.value !== null && (
            <Button.Action tooltip="Feld leeren" onClick={() => field.onChange(null)}>
              <XMarkIcon className="h-5 w-5" />
            </Button.Action>
          )}
        </div>
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}
export default SelectInput;
