import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Controller } from 'react-hook-form';

export function RadioInput(props) {
  const { control, disabled = false, items, label, secondaryLabel, name, defaultValue, rules } = props;
  return (
    <Controller
      render={({ field }) => (
        <>
          <RadioGroup
            value={field.value}
            onChange={field.onChange}
            className={`grid grid-cols-1 gap-y-6 sm:grid-cols-${items.length} sm:gap-x-4`}
          >
            {items.map((item, i) => (
              <RadioGroup.Option
                key={i}
                value={item.value}
                className={({ active, checked }) =>
                  `group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none ${
                    checked && 'border-indigo-600'
                  } data-[focus]:ring-2 data-[focus]:ring-indigo-600`
                }
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex flex-1">
                      <span className="flex justify-between flex-col">
                        <span className="block text-sm font-medium text-gray-900">{item.title}</span>
                        <span className="mt-1 flex items-center text-sm text-gray-500">{item.description}</span>
                      </span>
                    </span>
                    <CheckCircleIcon
                      aria-hidden="true"
                      className={`h-5 w-5 text-indigo-600 ${!checked && 'invisible'}`}
                    />
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </>
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
}
