import { useHasFeature } from '@/hooks/useFeatureFlags';
import {
  ChartBarIcon,
  CloudArrowUpIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
  HomeIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import React, { ReactElement } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useGlobalTransition from '../hooks/useGlobalTransition';
import useSdk from '../hooks/useSdk';
import useUser from '../hooks/useUser';
import { classNames } from '../util/classNames';
import { getLink, isActiveParent } from '../util/router';
import Spinner from './Spinner';

function Sidemenu() {
  const { api, isAppsite } = useSdk();
  const user = useUser();
  const { hasFeature } = useHasFeature();
  if (!api?.shortID || !isAppsite) {
    return <></>;
  }

  return (
    <div className="p-4 border-r h-full">
      {api.shortID && isAppsite && (
        <div>
          {hasFeature('ho-migration') && (
            <NaviLink.Level1
              label="Hector One Migration"
              link={[api.shortID, 'hector-one-migration']}
              Icon={CloudArrowUpIcon}
              permissions={!!user.isDSBAdmin}
            />
          )}

          <NaviLink.Level1
            label="Dashboard"
            link={[api.shortID, 'dashboard']}
            Icon={Squares2X2Icon}
            permissions={true}
          />
          <NaviLink.Level1
            label="Web"
            // link={!user.canEditWebsite ? [api.shortID, 'web', 'releases'] : [api.shortID, 'web']}
            // matcher={(path) => path.includes('/web')}
            link={[api.shortID, 'web']}
            redirect="pages"
            permissions={!!user.canRelease || !!user.canWeb}
            Icon={GlobeAltIcon}
          >
            <NaviLink.Level2
              label="Seiten verwalten"
              link={[api.shortID, 'web', 'pages']}
              permissions={!!user.canEditWebsite}
            />

            <NaviLink.Level2
              label="Style"
              link={[api.shortID, 'web', 'settings', 'style']}
              permissions={user.canEditSettings}
            />

            <NaviLink.Level2
              label="Navigation"
              link={[api.shortID, 'web', 'settings', 'nav']}
              permissions={user.canEditSettings}
            />

            <NaviLink.Level2
              label="SEO"
              link={[api.shortID, 'web', 'settings', 'seo']}
              permissions={user.canEditSettings}
            />

            <NaviLink.Level2
              label="Weiterleitungen"
              link={[api.shortID, 'web', 'settings', 'redirects']}
              permissions={user.canEditSettings}
            />

            <NaviLink.Level2
              label="Textverwaltung"
              link={[api.shortID, 'web', 'settings', 'text']}
              permissions={user.canEditSettings}
            />

            <NaviLink.Level2
              label="Veröffentlichungen"
              link={[api.shortID, 'web', 'releases']}
              permissions={!!user.canRelease}
            />
          </NaviLink.Level1>

          <NaviLink.Level1 label="App" link={[api.shortID, 'app']} redirect="club-content" Icon={DevicePhoneMobileIcon}>
            {/* <NavLink.Level2 label="App-Inhalte verwalten" link={[api.shortID, 'app', 'content']} /> */}
            {/* <NavLink.Level2 label="Club-Inhalte" link={[api.shortID, 'app', 'club-content_old']} /> */}
            <NaviLink.Level2 label="Dashboard" link={[api.shortID, 'app', 'dashboard']} permissions={!!user.canApp} />
            <NaviLink.Level2
              // label="Club-Infos"
              label="Neues aus dem Club"
              link={[api.shortID, 'app', 'club-content']}
              permissions={!!user.canApp}
            />
            {!hasFeature('ho-contentStudio') && (
              <NaviLink.Level2
                label="Inhalte"
                link={[api.shortID, 'app', 'content']}
                redirect={!hasFeature('ho-contentStudio') ? 'deep-links' : 'articles'}
              />
            )}
            <NaviLink.Level2 label="Style" link={[api.shortID, 'app', 'style']} permissions={!!user.canEditSettings} />
            <NaviLink.Level2 label="Textverwaltung" link={[api.shortID, 'app', 'text']} permissions={!!user.canApp} />
            <NaviLink.Level2
              label="Einstellungen"
              link={[api.shortID, 'app', 'settings']}
              redirect="general"
              permissions={!!user.canEditSettings}
            />
            <NaviLink.Level2
              label="Push-Notifications"
              link={[api.shortID, 'app', 'push']}
              permissions={!!user.canMarketing}
            />
            <NaviLink.Level2
              label="Push-Templates"
              link={[api.shortID, 'app', 'templates-push']}
              permissions={!!user.canApp}
            />
          </NaviLink.Level1>

          {hasFeature('ho-contentStudio') && (
            <NaviLink.Level1
              label="Content Studio"
              link={[api.shortID, 'content-studio']}
              redirect="plan"
              Icon={PaperAirplaneIcon}
            >
              <NaviLink.Level2
                label="Content Planung"
                link={[api.shortID, 'content-studio', 'plan']}
                permissions={!!user.canEditSettings}
              />

              <NaviLink.Level2
                label="Kategorien"
                link={[api.shortID, 'content-studio', 'categories']}
                permissions={!!user.canEditSettings}
              />
              <NaviLink.Level2
                label="App Content"
                link={[api.shortID, 'content-studio', 'content']}
                permissions={!!user.canEditSettings}
              />
              <NaviLink.Level2
                label="Kommunikation"
                link={[api.shortID, 'content-studio', 'campaigns']}
                permissions={!!user.canEditSettings}
              />
              <NaviLink.Level2
                label="E-Mail Templates"
                link={[api.shortID, 'content-studio', 'email-templates']}
                permissions={!!user.canEditSettings}
              />
              <NaviLink.Level2
                label="Abonnenten & Zielgruppen"
                link={[api.shortID, 'content-studio', 'user']}
                permissions={!!user.canEditSettings}
              />
            </NaviLink.Level1>
          )}

          <NaviLink.Level1
            label="Mitgliedschaft"
            link={[api.shortID, 'membership']}
            redirect="schedule"
            Icon={CreditCardIcon}
            permissions={!!user.canEditAddonConfig || !!user.canEditSchedule}
          >
            {hasFeature('ho-stripe') ? (
              // Menu for Stripe Prototype

              <>
                <NaviLink.Level2
                  label="Abos"
                  link={[api.shortID, 'membership', 'onlineMembershipTemplates']}
                  permissions={!!user.canEditMembership}
                />

                <NaviLink.Level2
                  label="Standorte"
                  link={[api.shortID, 'membership', 'locations']}
                  permissions={!!user.canEditMembership}
                />
              </>
            ) : (
              <>
                <NaviLink.Level2
                  label="Aktions-Planung"
                  link={[api.shortID, 'membership', 'schedule']}
                  permissions={!!user.canEditMembership && !!user.canEditSchedule}
                />
                <NaviLink.Level2
                  label="Abos"
                  link={
                    hasFeature('ho-onlineMembershipTemplates')
                      ? [api.shortID, 'membership', 'onlineMembershipTemplates']
                      : [api.shortID, 'membership', 'contracts']
                  }
                  permissions={!!user.canEditMembership}
                />
                <NaviLink.Level2
                  label="ADD-ONs"
                  link={
                    hasFeature('ho-addons')
                      ? [api.shortID, 'membership', 'addon-templates']
                      : [api.shortID, 'membership', 'addons']
                  }
                  permissions={!!user.canEditAddonConfig}
                />
                <NaviLink.Level2
                  label="Gutscheine"
                  link={
                    hasFeature('ho-onlineMembershipTemplates')
                      ? [api.shortID, 'membership', 'vouchers']
                      : [api.shortID, 'membership', 'vouchers-old']
                  }
                  permissions={!!user.canEditSettings || !!user.canVouchers}
                />
                <NaviLink.Level2
                  label="E-Mails"
                  link={[api.shortID, 'membership', 'email']}
                  permissions={!!user.canEditSettings}
                />
                <NaviLink.Level2
                  label="Kontakteinstellungen"
                  link={[api.shortID, 'membership', 'default-contact-settings']}
                  permissions={!!user.canEditSettings && !!user.canEditMembership}
                />
                <NaviLink.Level2
                  label="Zustimmungen"
                  link={[api.shortID, 'membership', 'consents']}
                  permissions={!!user.canEditSettings && !!user.canConsentCampaign && !!user.canConsentEntries}
                />
                <NaviLink.Level2
                  label="Kündigung"
                  link={[api.shortID, 'membership', 'cancel']}
                  permissions={!!user.canEditSettings}
                />
                <NaviLink.Level2
                  label="Bistro-Konto"
                  link={[api.shortID, 'membership', 'bistro']}
                  permissions={!!user.canEditSettings}
                />
                <NaviLink.Level2
                  label="Rechtstexte"
                  link={[api.shortID, 'membership', 'documents']}
                  permissions={!!user.canEditSettings}
                />
                {hasFeature('ho-onlineMembershipTemplates') && (
                  <NaviLink.Level2
                    label="Vertragstexte"
                    link={[api.shortID, 'membership', 'text-templates']}
                    permissions={!!user.canEditMembership}
                  />
                )}
                <NaviLink.Level2
                  label="Freunde werben"
                  link={[api.shortID, 'membership', 'referral']}
                  permissions={!!user.canEditMembership}
                />
                <NaviLink.Level2
                  label="Mitgliederbereich"
                  link={[api.shortID, 'membership', 'membersarea']}
                  permissions={!!user.canEditSettings}
                />
              </>
            )}
          </NaviLink.Level1>

          {hasFeature('ho-accounts') && (
            <NaviLink.Level1
              label="Mitglieder"
              link={[api.shortID, 'club-members']}
              redirect="requests"
              Icon={UsersIcon}
              permissions={!!user.canMembershipRequests || !!user.canHoAccounts}
            >
              <NaviLink.Level2
                label="Mitgliedschaftsanträge"
                link={[api.shortID, 'club-members', 'membership-requests']}
                permissions={!!user.canMembershipRequests}
              />
              {hasFeature('ho-cancellations') && (
                <>
                  <NaviLink.Level2
                    label="Kündigungen"
                    link={[api.shortID, 'club-members', 'cancellation-requests']}
                    permissions={!!user.canMembershipRequests}
                  />
                  <NaviLink.Level2
                    label="Auszeiten"
                    link={[api.shortID, 'club-members', 'breaks']}
                    permissions={!!user.canMembershipRequests}
                  />
                </>
              )}

              <NaviLink.Level2
                label="ADD-ON Buchungen"
                link={[api.shortID, 'club-members', 'addon-requests']}
                permissions={!!user.canMembershipRequests}
              />
              {hasFeature('ho-cancellations') && (
                <>
                  <NaviLink.Level2
                    label="ADD-ON Kündigungen"
                    link={[api.shortID, 'club-members', 'addon-cancellations']}
                    permissions={!!user.canMembershipRequests}
                  />
                </>
              )}
              <NaviLink.Level2
                label="Online Account"
                link={[api.shortID, 'club-members', 'online-accounts']}
                permissions={!!user.canHoAccounts}
              />
              <NaviLink.Level2
                label="Anreden"
                link={[api.shortID, 'club-members', 'salutations']}
                permissions={!!user.canHoAccounts}
              />
              {!hasFeature('ho-contentStudio') && (
                <NaviLink.Level2
                  label="Newsletter"
                  link={[api.shortID, 'club-members', 'newsletter']}
                  permissions={!!user.ho?.canReadNewsletter}
                />
              )}
              {!hasFeature('ho-contentStudio') && (
                <>
                  <NaviLink.Level2
                    label="Kommunikation"
                    link={[api.shortID, 'club-members', 'communication']}
                    permissions={!!user.ho?.canSeePushCampaign}
                  />
                  <NaviLink.Level2
                    label="Dynamische Zielgruppen"
                    link={[api.shortID, 'club-members', 'target-groups']}
                    permissions={!!user.ho?.canSeeTargetGroup}
                  />
                </>
              )}
            </NaviLink.Level1>
          )}

          <NaviLink.Level1 label="Clubs" link={[api.shortID, 'clubs']} redirect="settings" Icon={HomeIcon}>
            <NaviLink.Level2
              label="Einstellungen"
              link={[api.shortID, 'clubs', 'settings']}
              permissions={!!user.canEditSettings || !!user.canClubs}
            />
            <NaviLink.Level2
              label="Hec-Info"
              link={[api.shortID, 'clubs', 'hecinfo', 'edit']}
              permissions={!!user.canHecInfo}
            />
            <NaviLink.Level2
              label="Leistungen"
              link={[api.shortID, 'clubs', 'features']}
              permissions={!!user.canEditSettings || !!user.canClubs}
            />
            <NaviLink.Level2
              label="Kurse / Live-Kurse"
              link={[api.shortID, 'clubs', 'courses']}
              redirect="general"
              permissions={!!user.canEditSettings || !!user.canAppointments}
            />

            <NaviLink.Level2
              label="Homegym+"
              link={[api.shortID, 'clubs', 'homegym']}
              permissions={!!user.canEditSettings || !!user.canUpselling}
            />

            <NaviLink.Level2
              label="Mitarbeiter"
              link={[api.shortID, 'clubs', 'employees']}
              permissions={!!user.canEditSettings || !!user.canEmployees}
            />
            <NaviLink.Level2
              label="Termine"
              link={[api.shortID, 'clubs', 'appointments']}
              redirect="types"
              permissions={!!user.canEditSettings || !!user.canAppointments}
            />
            <NaviLink.Level2
              label="Social Media"
              link={[api.shortID, 'clubs', 'social-media']}
              permissions={!!user.canEditSettings}
            />
          </NaviLink.Level1>

          <NaviLink.Level1
            label="DSB Module"
            link={[api.shortID, 'dsb']}
            redirect="shop"
            Icon={SquaresPlusIcon}
            permissions={!!user.canEditSettings}
          >
            <NaviLink.Level2 label="Shop" link={[api.shortID, 'dsb', 'shop']} permissions={!!user.canEditSettings} />
            <NaviLink.Level2
              label="No-Covid-Nachweis"
              link={[api.shortID, 'dsb', 'covid']}
              permissions={!!user.canEditSettings}
            />
            <NaviLink.Level2 label="Chat" link={[api.shortID, 'dsb', 'chat']} permissions={user.canEditSettings} />

            <NaviLink.Level2 label="SECA" link={[api.shortID, 'dsb', 'seca']} permissions={!!user.canEditSettings} />
            {/* NOT NEEDED ? 
               <NavLink.Level2
                label="Einstellungen"
                link={[api.shortID, 'dsb', 'settings']}
                // permissions={!!user.canEditSettings}
              >
                <NavLink.Level3 label="Kurse" link={[api.shortID, 'dsb', 'settings', 'course']} />
              </NavLink.Level2> */}
          </NaviLink.Level1>

          <NaviLink.Level1
            label="Statistiken & Analytics"
            link={[api.shortID, 'stats']}
            Icon={ChartBarIcon}
            permissions={!!user.canReadStats || !!user.canEditSettings}
          >
            <NaviLink.Level2
              label="Hector Statistiken"
              link={[api.shortID, 'stats', 'overview']}
              permissions={!!user.canReadStats}
            />
            {hasFeature('ho-performanceMarketing') && (
              <NaviLink.Level2
                label="Performance Marketing"
                link={[api.shortID, 'stats', 'marketing-reports']}
                permissions={!!user.canReadStats}
              />
            )}
            {/* <NavLink.Level2 
              label="Reports" 
              link={[api.shortID, 'stats', 'reports']}
              permissions={!!user.canMarketingReports}  
            />  */}
            <NaviLink.Level2
              label="Einstellungen"
              link={[api.shortID, 'stats', 'analytics']}
              permissions={!!user.canEditSettings}
            />
          </NaviLink.Level1>

          <NaviLink.Level1
            label="Verwaltung"
            link={[api.shortID, 'manage']}
            Icon={InformationCircleIcon}
            permissions={!!user.canEditSettings || !!user.canAssetGroups || !!user.isSuperAdmin}
          >
            <NaviLink.Level2
              label="Übersicht"
              link={[api.shortID, 'manage', 'tba']}
              permissions={!!user.isSuperAdmin}
            />
            <NaviLink.Level2
              label="Medienverwaltung"
              link={[api.shortID, 'manage', 'assets']}
              permissions={!!user.canAssetGroups && !!user.canEditAssets}
            />
            <NaviLink.Level2
              label="Features"
              link={[api.shortID, 'manage', 'feature-flags']}
              permissions={!!user.isSuperAdmin}
            />
            <NaviLink.Level2
              label="Gruppen"
              link={[api.shortID, 'manage', 'groups']}
              permissions={!!user.canEditSettings}
            />
            <NaviLink.Level2
              label="Länder"
              link={[api.shortID, 'manage', 'countries']}
              permissions={!!user.canEditSettings}
            />
            <NaviLink.Level2
              label="Domain"
              link={[api.shortID, 'manage', 'domain']}
              permissions={!!user.canEditSettings}
            />
            {hasFeature('ho-onlineMembershipTemplates') && (
              <NaviLink.Level2
                label="Zahlungsanbieter"
                link={[api.shortID, 'manage', 'hec-pay']}
                permissions={!!user.ho?.canReadSecrets}
              />
            )}
            <NaviLink.Level2
              label="Nutzerverwaltung"
              link={[api.shortID, 'manage', 'users']}
              permissions={!!user.isSuperAdmin}
            />
            <NaviLink.Level2
              label="Änderungsverlauf"
              link={[api.shortID, 'manage', 'history']}
              permissions={!!user.isSuperAdmin && !!user.canHistory}
            />

            <NaviLink.Level2
              label="Makroverwaltung"
              link={[api.shortID, 'manage', 'makros']}
              permissions={user.isSuperAdmin}
            />

            <NaviLink.Level2
              label="Einrichtungsassistent"
              link={[api.shortID, 'manage', 'setup-assistant']}
              permissions={!!user.canEditSettings}
            />
          </NaviLink.Level1>
        </div>
      )}
    </div>
  );
}
export default Sidemenu;

const NaviLink = {
  Level1,
  Level2,
};

function Level1({
  Icon,
  label,
  children,
  link,
  redirect,
  matcher,
  permissions = true,
}: {
  Icon: any;
  label: React.ReactNode;
  link: any[];
  redirect?: string;
  matcher?: (path: string) => boolean;
  children?: ReactElement | ReactElement[];
  permissions?: boolean;
}) {
  const hasChildren = React.Children.toArray(children).length > 0;
  const firstValidChild = React.Children.toArray(children).find(
    (child: ReactElement) => child.props.permissions ?? true,
  );

  if (!permissions || (hasChildren && !firstValidChild)) return null;

  return (
    <NavLink to={link.join('/')}>
      {({ isActive, isTransitioning }) => (
        <>
          <div
            className={classNames(
              isActive && 'bg-gray-100 dark:bg-gray-700 text-indigo-600 dark:text-gray-100',
              'group bg-white dark:bg-gray-800 rounded-md py-3 px-3 flex items-center text-sm font-medium tracking-wide text-gray-700 hover:text-gray-900 dark:text-gray-300 cursor-pointer',
            )}
          >
            {!isTransitioning ? (
              <Icon className=" text-gray-600 dark:text-gray-300 mr-4 flex-shrink-0 h-5 w-5" />
            ) : (
              <Spinner />
            )}
            {label}
          </div>
          {isActive && children && <div>{children}</div>}
        </>
      )}
    </NavLink>
  );
}

function Level2({
  label,
  link,
  redirect,
  permissions = true,
  children,
}: {
  label: React.ReactNode;
  link: any[];
  redirect?: string;
  permissions?: boolean;
  children?: ReactElement | ReactElement[];
}) {
  if (!permissions) return null;
  return (
    <>
      <NavLink
        to={getLink(link, redirect)}
        className={classNames(
          isActiveParent(link) && 'text-indigo-600 dark:text-indigo-400',
          'group rounded-md py-2 px-3 pl-12 flex items-center text-sm text-gray-500 hover:text-gray-800  dark:text-gray-300 dark:hover:text-gray-500 cursor-pointer',
        )}
      >
        {label}
      </NavLink>
    </>
  );
}

function LazyLink({ to, options, children, ...props }: any) {
  const navigate = useNavigate();
  const startTransition = useGlobalTransition();

  return (
    <a
      href={to}
      onClick={(e) => {
        console.log(e);
        if (!(e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          startTransition(() => navigate(to, options));
        }
      }}
      {...props}
    >
      {children}
    </a>
  );
}
